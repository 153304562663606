import React from "react"
import useTranslations from "../../../components/useTranslations"
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import {LocaleContext} from "../../../components/layout";
import LocalizedLink from "../../../components/localizedLink";

const SoftDownload = () => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations
    const { softDownload } = useTranslations()
    const { locale } = React.useContext(LocaleContext)
    const SoftDownloadJa = () => {
        return(
            <ul className="software-ul flex wrap">
 
            <p>「Microsoft Edge」にて本サイトのソフトウェアダウンロードをご利用の際に「このサイトは安全ではないサイトとして報告されています」という警告が表示される場合があります。<br/>
            現在、この問題に対応するための対策を進めています。<br/>
            警告が表示されるお客様は、Google ChromeやSafari等、別のウェブブラウザーからご利用ください。<br/>
            ご迷惑ご不便をおかけいたしますが、解決まで今しばらくお待ちいただきますようお願い申し上げます。</p>
 


                <li className="software-li ">
                    <LocalizedLink className={'medium'} to={`/support/software-download/110TRH-B-DATA`} target={"_blank"} rel="noreferrer">デジタル温湿度計 SK-110TRH-B　ファームウェアアップデート</LocalizedLink>
                </li> 
                <li className="software-li ">
                    <LocalizedLink className={'medium'} to={`/support/software-download/110TRH-B`} target={"_blank"} rel="noreferrer">デジタル温湿度計 SK-110TRH-B用</LocalizedLink>
                </li> 
                <li className="software-li ">
                    <LocalizedLink className={'medium'} to={`/support/software-download/270WP-B-DATA`} target={"_blank"} rel="noreferrer">防水型無線温度計 SK-270WP-B　ファームウェアアップデート</LocalizedLink>
                </li> 
                <li className="software-li ">
                    <LocalizedLink className={'medium'} to={`/support/software-download/270WP-B`} target={"_blank"} rel="noreferrer">防水型無線温度計 SK-270WP-B用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku74j`} target={"_blank"} rel="noreferrer">記憶計 SK-L750シリーズ用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/sk-musen`} target={"_blank"} rel="noreferrer">無線温度・温湿度ロガー SK-L700Rシリーズ用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/barometer`} target={"_blank"} rel="noreferrer">デジタル気圧計 SK-500B用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/four-chLogger`} target={"_blank"} rel="noreferrer">４ｃｈ温度ロガー SK-L400T用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku62j`} target={"_blank"} rel="noreferrer">記憶計 SK-L200Ⅱシリーズ用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/two-chLogger`} target={"_blank"} rel="noreferrer">2チャンネル温度ロガー SK-L210T用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/psychrometer`} target={"_blank"} rel="noreferrer">通風乾湿計による湿度計算シート</LocalizedLink>
                </li>
            </ul>
        )
    }
    const SoftDownloadEn = () => {
        return(
            <ul className="software-ul flex wrap">
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku74j`} target={"_blank"} rel="noreferrer">Software for SK-L750 Series Dataloggers</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/barometer`} target={"_blank"} rel="noreferrer">Software for Digital Barometer Model SK-500B</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/four-chLogger`} target={"_blank"} rel="noreferrer">Software for 4-ch Datalogger Model SK-L400T</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku62j`} target={"_blank"} rel="noreferrer">Software for SK-L200 and SK-L200II Series Dataloggers</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/psychrometer`} target={"_blank"} rel="noreferrer">Aspirated Psychrometer Calculation Sheet</LocalizedLink>
                </li>
            </ul>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    <h2 className={'large bold'}>{softDownload}</h2>
                    <div className="content-container">
                        {locale === 'ja'?
                            <SoftDownloadJa/>:
                            <SoftDownloadEn/>
                        }
                    </div>
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default SoftDownload
